import React from 'react'
import { graphql } from 'gatsby'

import {
  AvatarBox,
  Avatar,
  Wrapper,
  Spacer,
  LinkBase,
  Name,
  Container,
} from '../components/atoms'

import { Flex, Heading, Text, Box } from 'theme-ui'


import Layout from '../components/layout'

export const ProfileTemplate = props => {

  return (
    <Layout>
      <Container>  
        <Spacer>
          {/* {post && (
            <Box width={[1, 1, 7 / 12]}>
              <Text>Team > {post.name}</Text>
              <Heading
                fontSize={[3, 4, 4]}
                mt={2}
                mb={2}
                fontWeight="bold"
                color="magenta"
              >
                {post.name}
              </Heading>
              <Box pt={3}>
                <Text fontSize={[3, 4, 3]} pb={2}>
                {post._rawBio[0].children[0].text}
                </Text>                
              </Box>
            </Box>
          )} */}
        </Spacer>        
      </Container>
    </Layout>
  )
}

export default ProfileTemplate
